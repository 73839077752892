.nv__header{
    width: 100%;
    height: auto;
    color: $white-color;
    position: fixed;
    z-index: 99;
    background: rgba($color: $strong-blue-color, $alpha: .6);

    &__container{
        width: 1170px;
        height: auto;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 10px 0px 10px 0px;

    }

    &__logos{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    &__logo{
        width: 180px;
        height: auto;
        padding-top: 10px;
        &--mobile{
            width: 100px;
            display: block;
            margin-left: 30px;
            margin-bottom: 30px;
        }

        
    }

    &__logo__ptg{
        width: 240px;
        height: auto;
        margin-left: 30px;
        &--mobile{
            display: block;
            margin-bottom: 30px;
        }
    }



    &__menu{
        width: 600px;
        height: auto;
        justify-content: flex-end;
;

        &__mobile{
            width: 100%;
            height: auto;

            li {
                font-size: 18px !important;
                margin-bottom: 30px;
                
            }
        }

        &--mobile{
            display: none; 
        }

        &--web{
            display: flex;
        }


        li{
            cursor: pointer;
            list-style: none;
            color: $white-color;
            margin-right: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            &:hover{
                .nv__header__submenu{
                    display: block;
                }
            }
        }
        :last-child{
            margin-right: 0;
        }

        a{   
            width: auto;
            height: auto;
            font-size: 16px;
            line-height: 26px;
            font-weight: 600;
            box-sizing: border-box;
            padding: 3px;

            background-image: linear-gradient(
                to right,
                $white-color 50%,
                $white-color 50%,
                $white-color  50%
            );
            background-size: 200% 100%;
            background-position: -100%;
            display: inline-block;
            padding: 5px 0;
            position: relative;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all 0.3s ease-in-out;

            &:before{
                content: '';
                background:  $white-color;
                display: block;
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 0;
                height: 3px;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                background-position: 0;
                background-color: $white-color;
                color: $strong-blue-color; 
            }
        
            &:hover::before{
                width: 100%;
            }
        }
    }


    &__submenu{
        width: 180px;
        height: auto;
        position: absolute;
        display: none; 
        top: 0px;
        padding-top: 60px;

        &__container{
            width: 100%;
            height: auto;
            margin: 0 auto;
            padding: 20px 0px 20px 0px;
            background: rgba(0, 0, 0, 0.68);
            
            li{
                min-width: auto ;
                font-size: 14px !important;
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 10px !important;
                box-sizing: border-box;
                &:last-child{
                    margin-bottom: 0px !important;
                }
            }
            a{
                display: block;
                background-color: transparent;
                &:hover{
                    color: $blue-color !important;
                }    
            }
        }
    }

    &__button__section{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 280px;
    }
}



.scrolled{
    background: rgba(0, 0, 0, 0.68);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

@media screen and (max-width: 1170px){
    .nv__header{
        &__container{
            width: 100%;
        }
    }
}

@media screen and (max-width: 992px){
    .nv__header{
        &__menu{
            &--web{
                width: 500px;
            }

            li{
                margin-right: 10px;
                font-size: 14px;
            }
        }

        .nv__button{
            width: 140px;
        }

        &__logo__ptg{
            width: 120px;
            margin-left: 20px;
        }
    
    }
}

@media screen and (max-width: 840px){
    .nv__header{
        &__container{
            justify-content: space-between;
        }

        &__logo{
            margin-left: 20px;
        }

        .nv__button{
            width: 140px;
            margin-right: 20px;
        }

    }
}

@media screen and (max-width: 820px){
    .nv__header{

        &__menu{
            &--web{
                display: none;
            }

            &--mobile{
                width: 40px;
                display: block;
                font-size: 28px;
                cursor: pointer;
            }
        }

        &__menu{
            li{
                display: block;
                text-align: left;
            }
        }
        
        &__submenu{
            display: none;
        }

        .nv__button{
            display: none
        }

        &__button__section{
            width: auto;
        }
    }
}

@media screen and (max-width: 420px){
    .nv__header{

        &__logo{
            width: 100px;
        }

        &__logo__ptg{
            width: 100px;
            margin-left: 20px;
        }

        &__button__section{
            
            width: 100px;
        }
    }
}