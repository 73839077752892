.nv__alliances__menu{
    width: 40px;
    height: auto;
    position: fixed;
    top: 40%;
    left: 0px;
    bottom: auto;
    z-index: 99;
    -webkit-box-shadow: 6px 6px 7px -5px rgb(0 0 0 / 75%);
    -moz-box-shadow: 6px 6px 7px -5px rgba(0, 0, 0, 0.75);
    box-shadow: 6px 6px 7px -5px rgb(0 0 0 / 75%);
    background: rgb(0 0 0 / 75%);
    
    &__item{
        width: 80%;
        height:auto;
        position: relative;
        margin: 0 auto;
        margin-bottom: 20px;
        cursor: pointer;
        svg{
            font-size: 30px;
            color: $white-color;
            display: block;
            margin: 0 auto; 
            &:hover{
                color: $blue-color;
            } 
        }
        
        &:first-child{
            margin-top: 10px;
        }
        &:last-child{
            margin-bottom:10px;
        }
    }
}
