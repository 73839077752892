.nv__slider{
    width: 100%;
    height: auto;

    &__item{
        width: 100%;
        height: 90vh;
        color:$white-color;
        position: relative;
        background-size: cover !important;
        background-position: center center !important;

        video{
            height: 100%;
            width: 100%;
            object-fit: cover;
            top: 0;
            padding: none;
        }
    }

    &__overlay{
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
    }

    &__logo{
        width: 240px;
        height: auto;

        &__slogan{
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-family: "Montserrat-SemiBold";
        }
    }
    
}

.swiper-pagination-bullet-active{
    background: $blue-color !important;
}


@media screen and (max-width: 992px){
    .nv__slider{
        &__item{
            height: 600px;
        }

        &__logo{
            width: 200px;
            height: auto;

            &__slogan{
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .nv__slider{
        &__item{
            height: 480px;
        }

        &__logo{
            width: 160px;
            &__slogan{
                font-size: 10px;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .nv__slider{
        &__item{
            height: 380px;
        }

    
    }
}
