.nv__about__us{
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 80px;
    padding-top: 120px;

    .image__container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;

        &__block{
            position: relative;
            margin-bottom: 30px;

            &__left{
                width: 65%;
                height: 540px;
                &__image{
                    width: 100%;
                    height: 100%;
                    background: url('../../../assets/images/home/about-us/mision-test.jpeg');
                    background-size: cover !important;
                    background-position: center center !important;
                }
            }

            &__right{
                width: 30%;
                height: 540px;
                &__image{
                    width: 100%;
                    height: 100%;
                    background: url('../../../assets/images/home/about-us/vision-test.jpg');
                    background-size: cover !important;
                }
            }
        }
    }

    .text__container{
        display: flex;
        justify-content: space-between;
        &__text{
            &--big{
                width: 65%;
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;    
            }
            &--small{
                width: 30%;
                height: auto;
            }


            .main__title{
                width: 48%;
                height: auto;
                
            }

            .main__info{
                width: 50%;
            }

            h2{
                font-size: 30px;
                line-height: 36px;
            }

            h4{
                font-size: 18px;
                line-height: 100%;
                margin-bottom: 10px;
            }

            p{
                text-align: justify;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

@media screen and (max-width: 1170px){
    .nv__about__us{
        width: 100%;
        
    }
}

@media screen and (max-width: 992px){
    .nv__about__us{
        width: 100%;

        
        .nv__section__container{

            h2{
                font-size: 26px;
                line-height: 32px;
            }

            h4{
                font-size: 16px;
            }

            p{
                font-size: 14px;
            }
        }

        .image__container{

            &__block{
                position: relative;
                margin-bottom: 30px;
    
                &__left{
                    height: 440px;
                }
    
                &__right{
                    height: 440px;
                }
            }

        }

        .text__container{
            display: block;
            &__text{
                
                &--big{
                    width: 100%;
                    height: auto;
                    display: block;
                }
                &--small{
                    width: 100%;
                    height: auto;
                }

                .main__title{
                    width: 100%;
                    height: auto;
                    margin-bottom: 20px;
                    
                }
    
                .main__info{
                    width: 100%;
                }
            }
        }

        
    }
}

@media screen and (max-width: 768px){

    .nv__about__us{
        .image__container{
            &__block{
    
                &__left{
                    height: 320px;
                }
    
                &__right{
                    height: 320px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px){

    .nv__about__us{
        .image__container{
            flex-wrap: wrap;
            &__block{
                &__left{
                    width: 100%;
                    height: 220px;
                }
    
                &__right{
                    width: 100%;
                    height: 220px;
                }
            }
        }

        .nv__section__container{

            h2{
                font-size: 22px;
                line-height: 28px;
            }
    
        }
    }
}

@media screen and (max-width: 380px){

    .nv__about__us{
        .image__container{
            &__block{
                &__left{
                    height: 170px;
                }
    
                &__right{
                    height: 170px;
                }
            }
        }

        
    }
}


 