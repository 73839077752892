.ptg__page{
    width: 100%;
    height: 100vh;
    background: url('../../../assets/images/ptg/slider2.jpg') no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;

    h1{
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    p{
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 30px;
        text-align: justify;
    }
    &__section{
        max-width: 1170px;
        height: auto;
        margin: 0 auto;
    }

    &__footer{
        max-width: 1170px;
        height: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        margin: 0 auto;
        bottom: 30px;
        display: flex;
        justify-content: space-between;

        .btn__action{
            padding: 0px 20px;
            height: 40px;
            color: $white-color;
            border: none;
            border-radius: 5px;
            font-size: 18px;
            line-height: 28px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                background: $blue-color;
                color: $white-color;
            }
        }
    }
}


@media screen and (max-width: 1170px){
    .ptg__page{
        &__section{
            width: 80%;
        }
    }
}


@media screen and (max-width: 992px){
    .ptg__page{
        h1{
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 30px;
        }
    
        p{
            font-size: 18px;
            line-height: 28px;
        }
    }
}


@media screen and (max-width: 580px){
    .ptg__page{
        h1{
            font-size: 26px;
            line-height: 36px;
        }
    
        p{
            font-size: 16px;
            line-height: 26px;
        }
    }
}
